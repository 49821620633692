//
//  Porto Compatibility Styles
//  __________________________

//
//  Common
//  ----------------------------------------------


.am-porto-cmtb {
    .page-header.type1 {
        z-index: 9999;
    }

    .amsearch-clone-position {
        padding: 2px 0;
        border: 15px solid transparent;
        border-top: none;
        border-radius: 0 0 $amsearch__indent__l $amsearch__indent__l;

        .amsearch-results {
            border-radius: 0 0 6px 6px;
            box-shadow: 0 0 3px rgba(0, 0, 0, .25);
        }
    }

    .amsearch-autocomplete-information .product-reviews-summary .reviews-actions .action {
        margin: 0;
        padding: 0;
        border: 0;
    }
}
