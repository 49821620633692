body .header.content {
    overflow: visible;
    justify-content: unset;

    .logo {
        width: 100%;
    }

    .form.minisearch {

        .control {
            width: unset;
            border-top: 0;
            margin: 0;
            padding: 0;
            clear: both;
            height: unset;
            transform: none;
            opacity: 1;
        }

        >.field {
            position: relative;

            @include respond-to-up($tweakpoint-nav-toggle) {
                position: unset;
                z-index: unset;
                margin-bottom: 0;
            }

            .label {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 9999;
                display: block;
                text-align: center;
                border: 2px solid $black;
                border-left: 0;

                &:before {
                    display: inline-block;
                    line-height: 45px;
                }

                &.active {
                    &:before {
                        content: map-get($ms-icons, 'x');
                    }
                }
            }

            .amsearch-loupe,
            .amsearch-close {
                display: none !important;
            }

            .amasty-xsearch-loader {
                right: 100px;
            }
        }
    }

    .block-search {
        position: absolute;
        top: 100%;
        width: 100%;
        left: 0;
        z-index: 0;

        @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
            padding: 1rem;
            background-color: $white;
            border-top: 1px solid map-get($theme-colors, 'light');
        }

        @include respond-to-up($tweakpoint-nav-toggle) {
            margin-right: unset;
            margin-left: unset;
            position: relative;
            z-index: 1;
        }

        .block-content {
            margin-bottom: 0;
        }
    }

    .amsearch-wrapper-input {
        position: relative;
        max-width: 100%;
        transition: none;
    }
}

#search {
    border-radius: 0;
    padding: 4px 35px 0 26px;
    width: 100%;
    position: static;
    margin: 0;
    font-size: 16px;
    left: -300%;
    border: 2px solid $black;
    height: 46px;
    font-weight: bold;
    line-height: 46px;

    &:focus {
        border-color: $black;
    }

    @include respond-to-up($tweakpoint-nav-toggle) {
        font-size: 20px;
    }

    &::placeholder {
        color: $black;
        line-height: 45px;
    }

}

#search_autocomplete {
    border: 0 !important;
    position: absolute;

    .amsearch-item-container,
    .amsearch-item-container .amsearch-highlight {
        font-size: 100%;
        letter-spacing: -0.02em;
    }

    .amsearch-products .information-container .amsearch-highlight {
        background-color: transparent !important;
        color: map-get($theme-colors, 'primary');
    }

    .amsearch-item-container .amsearch-highlight {
        color: map-get($theme-colors, 'primary');
    }

    .amsearch-item > .item-name {
        font-weight: bold;
        color: $black;
        text-decoration: none;
        font-size: 100%;

        &:hover {
            text-decoration: underline;
        }
    }

    .price-box {
        margin-top: .5rem;
        margin-bottom: .5rem;
        width: 100%;

        .price {
            font-size: 1.25rem;
        }
    }

    .amasty-xsearch-block-header {
        font-family: $font-family-condensed;
        letter-spacing: -0.02em;
        font-size: 20px;
    }

    .amsearch-item-container.popular_searches .amasty-xsearch-block-header {
        padding: 0 0 10px;
    }

    .amsearch-item-container.recent_searches .amsearch-item:hover {
        border-color: map-get($theme-colors, 'primary');
    }

    .amsearch-item-container.recent_searches .amsearch-item > a {
        font-size: 100%;
    }

    .actions-primary {
        //width: 100%;
    }

    .product.actions.product-item-actions {
        justify-content: center;
        width: 100%;
    }
}

body .amsearch-results {
    padding-bottom: 15px;

    @include respond-to-up($tweakpoint-nav-toggle) {
        padding-top: 0;
    }
}

/* top spacing breadcrumbs on mobile  */
body .page-wrapper .breadcrumbs {
    @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
        margin-top: 5rem;
    }
}

body.page-layout-2columns-left .page-wrapper .breadcrumbs {
    @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
        margin-top: 6rem;
    }
}

/* top spacing without breadcrumbs on mobile */
body  {
    &.cms-index-index .column.main,
    &.page-layout-1column .page-main {
        @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
            margin-top: 80px;
        }
    }
}

body .amsearch-category-tab .amsearch-item {
    font-size: 1rem;
}

body #amsearch-result-tabs .title.active .switch {
    padding-bottom: 1rem;
}

body #amsearch-result-tabs  {
    .data.item.content {
        padding: 1.5rem;
    }

    .title .switch {
        @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
            justify-content: flex-start;

            &:after {
                position: absolute;
                top: 24px;
                right: 21px;
            }
        }
    }

    .amsearch-related-terms > .amsearch-item {
        background-color: $black;
        color: $white;
        font-size: 100%;

        &:hover {
            background-color: map-get($colors, 'light-grey');
            color: $white;
        }
    }

    .amsearch-related-terms > .amsearch-title {
        font-family: $font-family-heading;
        text-transform: uppercase;
    }
}

body .product-item .action.tocompare {
    display: none;
}

body .amsearch-form-container .input-text:focus {
    box-shadow: 0 4px 5px 1px #a9a9a9;
}

body .amasty-xsearch-loader {
    background: $amxsearch-autocomplete-loader__background-image no-repeat center;
    background-size: cover;
}

/* override word wrapping settings within the autocomplete dropdown */
body .amsearch-item .product-item-description,
body .amsearch-item .item-description {
    word-break: break-word;
}

/* override link colour within the search result autocomplete */
body.is-search-open .amsearch-more-results .amsearch-link {
    border-bottom: unset;
    color: inherit;
    text-decoration: inherit;

    &:hover {
        border-bottom: unset;
        background-color: unset;
        color: inherit;
        text-decoration: inherit;
    }
}

/* re-order the search results - products to show first on mobile */
body .amsearch-products {
    order: unset;
}

/* hide description for the category list returned */
body .amsearch-results .amsearch-item-container.category .item-description {
    display: none;
}

/* reduce height of autocomplete dropdown due to view all link being cutt off some browsers */
body .amsearch-form-container .search-autocomplete {
    max-height: 85vh;
}

/* hide the description - which shows embedded styles with style tag omitted LGH-533 */
body .amsearch-category-tab .amsearch-item .item-description {
    display: none;
}
