@charset "UTF-8";
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**
 * Loader to be used across the site
 * Use this variable
 */
/**
 * Form Elements config
 */
/**
 * Collection of generic/global variables used across the theme
 */
#amasty-shopby-product-list {
  order: 10;
  max-width: 100%;
}

.amsearch-results {
  padding: 15px 0 0;
  background-color: #fff;
}

.amsearch-results .amsearch-products.-waste {
  padding: 15px;
}

.amsearch-results .amsearch-item {
  text-transform: capitalize;
  cursor: pointer;
}

.amsearch-results .amsearch-item:hover {
  background-color: #f4f5fa;
}

.amsearch-results .amsearch-item .item-name {
  outline: none;
  color: inherit;
  text-decoration: none;
  font-size: 1.2rem;
}

.amsearch-results .amsearch-item-container:not(.recent_searches) .amsearch-item .item-name {
  text-decoration: underline;
  cursor: pointer;
}

.amsearch-results .actions-secondary {
  display: none;
  padding: 0 0 0 5px;
}

.amsearch-results .amsearch-item .amsearch-autocomplete-image:not([data-click-url]),
.amsearch-results .amsearch-item .price-box:not([data-click-url]) {
  cursor: pointer;
}

.amsearch-form-container .control {
  min-height: 32px;
}

.amsearch-form-container .amsearch-wrapper-inner .actions-secondary {
  display: none;
}

.amsearch-form-container .search-autocomplete .amsearch-results .amsearch-leftside {
  max-width: 100%;
  background-color: #fff;
}

.amsearch-form-container .search-autocomplete {
  overflow: hidden;
  overflow-y: auto;
  scrollbar-color: #adadad rgba(255, 255, 255, 0.2);
  scrollbar-width: thin;
  overflow-y: auto;
  max-height: 90vh;
}

.amsearch-form-container .search-autocomplete::-webkit-scrollbar {
  background: #f0f0f0;
}

.amsearch-form-container .search-autocomplete::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #adadad;
  cursor: pointer;
}

.amsearch-form-container .search-autocomplete ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
}

.amsearch-form-container.-opened .input-text {
  padding-left: 26px;
}

.amsearch-form-container.-opened .input-text::-ms-clear {
  display: none;
}

.amsearch-form-container.-opened .input-text::-webkit-input-placeholder {
  color: #cecece;
  font-style: italic;
}

.amsearch-form-container.-opened .input-text::-moz-placeholder {
  color: #cecece;
  font-style: italic;
}

.amsearch-form-container.-opened .input-text:-ms-input-placeholder {
  color: #cecece;
  font-style: italic;
}

.amsearch-form-container .search-autocomplete .amsearch-leftside .amsearch-products {
  background-color: #fff;
}

.amsearch-wrapper-input {
  position: relative;
  max-width: 100%;
  transition: none;
}

.amsearch-wrapper-content > .amsearch-item {
  position: relative;
  display: flex;
  padding: 20px 0 16px 15px;
}

.amsearch-wrapper-content > .amsearch-item:first-child:before, .amsearch-wrapper-content > .amsearch-item:last-child:after {
  display: none;
}

.amsearch-wrapper-content .product-item.amsearch-item {
  padding: 15px;
}

.amsearch-wrapper-content .product-item-details {
  display: flex;
  flex-direction: column;
  min-height: 100px;
}

.amsearch-wrapper-content .amsearch-wrapper-inner {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-top: auto;
}

.amsearch-wrapper-content .product-item-link {
  margin: 0 0 5px;
  color: #333;
  word-break: keep-all;
}

.amsearch-wrapper-content .product-item-actions {
  display: flex;
  align-items: flex-end;
  margin-top: 6px;
}

.amsearch-wrapper-content .action.tocart {
  word-wrap: break-word;
  word-break: break-word;
  white-space: inherit;
  line-height: 1.2;
}

.ie11 .amsearch-wrapper-content .action.tocart {
  word-break: break-all;
}

.amsearch-wrapper-content .amsearch-wrapper-inner .price-box {
  margin: 0 10px 0 0;
}

.amsearch-wrapper-content .amsearch-wrapper-inner .price {
  font-size: 16px;
}

.block-search .search .control {
  position: relative;
  z-index: 99;
}

.block-xsearch.block-search .search .control {
  z-index: 98;
}

.amsearch-form-container .input-text:focus {
  border: 2px solid #54c6ff;
  box-shadow: 0 2px 4px #c2ebff;
}

.amsearch-close {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: none;
  margin: auto;
  padding: 16px;
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMWwxMCAxMG0wLTEwTDEgMTEiIHN0cm9rZT0iI0IyQjJCMiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPjwvc3ZnPg==) no-repeat center;
  cursor: pointer;
}

.search .amsearch-loupe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  display: none;
  margin: auto;
  padding: 0;
  width: 14px;
  height: 14px;
  border: 0;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1Ni45NjYgNTYuOTY2Ij48cGF0aCBkPSJNNTUuMTQ2IDUxLjg4N0w0MS41ODggMzcuNzg2QTIyLjkyNiAyMi45MjYgMCAwIDAgNDYuOTg0IDIzYzAtMTIuNjgyLTEwLjMxOC0yMy0yMy0yM3MtMjMgMTAuMzE4LTIzIDIzIDEwLjMxOCAyMyAyMyAyM2M0Ljc2MSAwIDkuMjk4LTEuNDM2IDEzLjE3Ny00LjE2MmwxMy42NjEgMTQuMjA4Yy41NzEuNTkzIDEuMzM5LjkyIDIuMTYyLjkyLjc3OSAwIDEuNTE4LS4yOTcgMi4wNzktLjgzN2EzLjAwNCAzLjAwNCAwIDAgMCAuMDgzLTQuMjQyek0yMy45ODQgNmM5LjM3NCAwIDE3IDcuNjI2IDE3IDE3cy03LjYyNiAxNy0xNyAxNy0xNy03LjYyNi0xNy0xNyA3LjYyNi0xNyAxNy0xN3oiIGZpbGw9IiNhOWE5YTkiLz48L3N2Zz4=) no-repeat center;
  box-shadow: none;
}

.amsearch-products .amsearch-highlight {
  padding: 0 2px;
  background-color: #b0aa00;
  color: #fff;
  word-wrap: normal;
}

.amsearch-products {
  order: 10;
  padding: 0 0 15px;
}

.amsearch-products .amasty-xsearch-block-header {
  padding: 0 15px 10px;
}

.amsearch-leftside {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.amsearch-leftside .recent_searches,
.amsearch-leftside .amsearch-item {
  flex-basis: 100%;
  padding: 5px 0;
}

.amsearch-autocomplete-information {
  padding-left: 13px;
  width: 60%;
}

.amsearch-autocomplete-information .action.view {
  color: #757575;
  font-weight: 300;
}

.amasty-xsearch-loader {
  position: absolute;
  top: 0;
  right: 40px;
  bottom: 0;
  z-index: 1000;
  margin: auto 5px auto 0;
  width: 16px;
  height: 11px;
  background: url(data:image/gif;base64,R0lGODlhEAALAPQAAP///wAAANra2tDQ0Orq6gYGBgAAAC4uLoKCgmBgYLq6uiIiIkpKSoqKimRkZL6+viYmJgQEBE5OTubm5tjY2PT09Dg4ONzc3PLy8ra2tqCgoMrKyu7u7gAAAAAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCwAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQJCwAAACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQJCwAAACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQJCwAAACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAkLAAAALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkECQsAAAAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAkLAAAALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkECQsAAAAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA) no-repeat center;
}

.amasty-message.message.info {
  margin: 0;
}

.amasty-xsearch-num-results {
  float: right;
}

.amsearch-more-results {
  margin-top: 30px;
  text-align: center;
}

.amsearch-more-results .amsearch-link {
  border-bottom: 1px solid #06c;
  color: #06c;
  text-decoration: none;
}

.amsearch-more-results .amsearch-link:hover {
  border-bottom: 1px solid #1979c3;
  background-color: inherit;
  color: #1979c3;
  text-decoration: none;
}

.block-search .action.search.amasty-xsearch-hide,
.amasty-xsearch-hide {
  display: none;
}

.amasty-xsearch-block-header {
  position: relative;
  margin-bottom: 0;
  padding: 0 0 5px;
  color: #000;
  text-transform: uppercase;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 700;
}

.amsearch-item-container {
  position: relative;
  flex-basis: 100%;
  padding: 0 15px 30px;
}

.amsearch-item-container:last-child:after {
  display: none;
}

.amsearch-item-container:not(.recent_searches) .amsearch-highlight {
  color: #b0aa00;
  font-weight: 600;
}

.amsearch-item-container .amsearch-item {
  cursor: inherit;
}

.amsearch-item-container .amsearch-item:hover {
  background-color: inherit;
}

.amsearch-item-container .amsearch-highlight {
  font-size: 12px;
}

.amsearch-item-container.page a.item-name {
  color: #b0aa00;
  font-weight: 600;
}

.amsearch-item-container.recent_searches {
  padding: 0 15px 20px;
}

.amsearch-item-container.recent_searches .amsearch-item {
  display: inline-block;
  order: 1;
  box-sizing: border-box;
  margin: 0 8px 10px 0;
  padding: 5px 10px;
  border: 1px solid #e2e4ee;
  border-radius: 25px;
  text-decoration: none;
  font-size: 12px;
}

.amsearch-item-container.recent_searches .amsearch-item:hover {
  border-color: #b0aa00;
}

.amsearch-item-container.recent_searches .amsearch-item:active {
  border-color: #4a4700;
}

.amsearch-item-container.recent_searches .amsearch-item:focus {
  border-color: #b0aa00;
  background-color: #b0aa00;
  color: #fff;
}

.amsearch-item-container.recent_searches .amasty-xsearch-block-header {
  padding: 0 0 10px;
}

.amsearch-item-container.recent_searches .amasty-xsearch-num-results {
  display: none;
}

.amsearch-item-container.popular_searches .amsearch-item:hover {
  background: #f4f5fa;
}

.amsearch-item-container.popular_searches .amasty-xsearch-num-results {
  display: none;
  font-size: 12px;
}

.amsearch-item-container.-brands .amasty-xsearch-num-results {
  display: inline-block;
  float: none;
  color: #ccc;
}

.amasty-xsearch-preload {
  display: none;
}

.search-autocomplete .amsearch-results div:not([data-click-url]) {
  background: none;
  cursor: default;
}

.amsearch-category-tab .amsearch-item {
  margin-bottom: 10px;
  font-size: 1.6rem;
}

.amsearch-category-tab .amsearch-item .am-item-link:not(:last-of-type):after {
  display: inline-block;
  margin: 0 10px;
  width: 10px;
  height: 10px;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNDUxLjg0NiA0NTEuODQ3Ij48cGF0aCBkPSJNMzQ1LjQ0MSAyNDguMjkyTDE1MS4xNTQgNDQyLjU3M2MtMTIuMzU5IDEyLjM2NS0zMi4zOTcgMTIuMzY1LTQ0Ljc1IDAtMTIuMzU0LTEyLjM1NC0xMi4zNTQtMzIuMzkxIDAtNDQuNzQ0TDI3OC4zMTggMjI1LjkyIDEwNi40MDkgNTQuMDE3Yy0xMi4zNTQtMTIuMzU5LTEyLjM1NC0zMi4zOTQgMC00NC43NDggMTIuMzU0LTEyLjM1OSAzMi4zOTEtMTIuMzU5IDQ0Ljc1IDBsMTk0LjI4NyAxOTQuMjg0YzYuMTc3IDYuMTggOS4yNjIgMTQuMjcxIDkuMjYyIDIyLjM2NiAwIDguMDk5LTMuMDkxIDE2LjE5Ni05LjI2NyAyMi4zNzN6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIGRhdGEtb2xkX2NvbG9yPSIjYTNhM2EzIiBmaWxsPSIjYTNhM2EzIi8+PC9zdmc+) no-repeat center;
  background-size: contain;
  content: '';
}

.block.block-search,
.form .search-autocomplete {
  z-index: 99;
}

.block.block-xsearch {
  z-index: 98;
}

@media only screen and (max-width: 767px) {
  .amsearch-leftside,
  .amsearch-products,
  .amsearch-additional-content > .amsearch-content-block {
    width: 100% !important;
  }
  .amsearch-products.-waste {
    width: auto !important;
  }
  .form .search-autocomplete {
    top: 66px;
    left: 0;
    overflow-y: auto;
    margin: -15px 15px 0;
    max-height: 70vh;
    background: #fff;
  }
}

.amsearch-item .product-item-description,
.amsearch-item .item-description {
  word-break: keep-all;
}

.amsearch-clone-position {
  position: absolute;
  right: 0;
  z-index: 999;
  box-shadow: 0 4px 6px rgba(61, 62, 66, 0.05), 0 4px 25px rgba(75, 76, 83, 0.15);
}

.amsearch-autocomplete-image {
  width: 40%;
}

.amsearch-autocomplete-image .product-image-photo {
  box-sizing: border-box;
  border: 1px solid #f1f1f1;
}

#amsearch-result-tabs {
  margin-top: 5px;
}

#amsearch-result-tabs .data.title {
  margin-right: 5px;
}

#amsearch-result-tabs .data.title.active {
  box-sizing: border-box;
  background: #d8d8d8;
}

#amsearch-result-tabs .data.title.last {
  margin-bottom: 8px;
}

#amsearch-result-tabs .title {
  position: relative;
  box-sizing: border-box;
}

#amsearch-result-tabs .title.active:before {
  position: absolute;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background: #fff;
  content: '';
}

#amsearch-result-tabs .title.active .switch {
  padding-bottom: 1rem;
}

#amsearch-result-tabs .title.active .amsearch-results-count {
  display: none;
}

#amsearch-result-tabs .data.content {
  display: none;
}

#amsearch-result-tabs .data.content[aria-hidden='false'] {
  display: block;
}

.amsearch-related-terms {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
}

.amsearch-related-terms > .amsearch-title {
  margin: 0 0 15px 0;
  width: 100%;
  font-weight: 700;
}

.amsearch-related-terms > .amsearch-item {
  margin: 0 15px 10px 0;
  padding: 5px 15px;
  border-radius: 20px;
  background: #d8eeff;
  color: #252525;
  font-size: 12px;
  line-height: 1.3;
  transition: .1s;
}

.amsearch-related-terms > .amsearch-item:hover {
  background: #b7e0ff;
}

.amsearch-related-terms > .amsearch-item:hover, .amsearch-related-terms > .amsearch-item:active {
  text-decoration: none;
}

.amsearch-related-terms .amsearch-count {
  color: #757575;
}

.amsearch-emptysearch-cms {
  padding: 30px;
  background: #fbfcfe;
  color: #1f1b1b;
}

.amsearch-emptysearch-cms > .amsearch-content {
  word-wrap: break-word;
  word-break: break-word;
}

.ie11 .amsearch-emptysearch-cms > .amsearch-content {
  word-break: break-all;
}

.amsearch-emptysearch-cms .amsearch-content > .amsearch-title {
  margin: 0 0 15px;
  font-weight: 800;
  font-size: 38px;
}

.amsearch-emptysearch-cms .amsearch-text {
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
}

.amsearch-emptysearch-cms .amsearch-contacts-block {
  margin-top: 30px;
  font-size: 14px;
}

.amsearch-emptysearch-cms .amsearch-image-block {
  margin-top: 30px;
}

.amsearch-emptysearch-cms .amsearch-contacts-block > .amsearch-title {
  margin-bottom: 5px;
}

@media screen and (max-width: 767.98px) {
  .catalogsearch-result-index .amsearch-pagebuilder-carousel .product-item-info {
    margin: 0 auto;
    width: 240px;
  }
}

.amsearch-form-container .actions-secondary {
  display: flex;
}

@media screen and (min-width: 768px) {
  .minisearch .actions .action.search {
    z-index: 1000;
  }
  .amsearch-form-container .input-text {
    padding-left: 14px;
  }
  .product-item-actions .actions-primary + .actions-secondary > .action {
    flex-grow: 1;
    width: auto;
    border: none;
  }
  .amasty-xsearch-loader {
    right: 30px;
  }
  .search-autocomplete {
    top: 37px;
    max-width: 75vw;
  }
  .amsearch-close,
  .search .amsearch-loupe:hover {
    -webkit-filter: brightness(0%);
    filter: brightness(0%);
  }
  .amsearch-results {
    display: flex;
    overflow: auto;
    padding: 0;
    max-width: 83vw;
  }
  .amsearch-results .amsearch-products {
    padding: 20px 0 0;
  }
  .amsearch-results .amsearch-leftside,
  .amsearch-results .amsearch-products {
    padding: 15px 0;
    width: inherit;
  }
  .amsearch-results .amsearch-item-container {
    padding-bottom: 25px;
  }
  .search-autocomplete.amsearch-clone-position {
    top: calc(100% + 5px);
  }
  .amsearch-wrapper-content > .amsearch-item {
    position: relative;
    padding: 20px 0 16px 15px;
  }
  .amsearch-wrapper-content > .amsearch-item:last-child {
    padding-bottom: 20px;
  }
  .amsearch-wrapper-content > .amsearch-item:first-child {
    padding-top: 15px;
  }
  .amsearch-wrapper-content .amsearch-autocomplete-information {
    padding-left: 10px;
    width: auto;
  }
  .amsearch-wrapper-content .product-item-details {
    padding-right: 14px;
    min-height: 100px;
  }
  .amsearch-wrapper-content .product-reviews-summary {
    margin-bottom: 0;
  }
  .search .amsearch-autocomplete-information .price {
    font-size: 14px;
  }
  .amsearch-item-container {
    padding: 0;
  }
  .amasty-xsearch-block-header {
    padding: 0 15px 5px;
  }
  .amsearch-item-container.popular_searches .amasty-xsearch-block-header {
    padding: 0 15px 10px;
  }
  .amsearch-form-container.-opened .action.search {
    display: none;
  }
  .amsearch-form-container.-opened:not(.-small) .amsearch-products {
    border-left: 1px solid #e8e9ef;
  }
  .amsearch-form-container.-opened .amsearch-leftside .recent_searches,
  .amsearch-form-container.-opened .amsearch-leftside .amsearch-item {
    padding: 5px 15px;
  }
  .amsearch-form-container.-opened .amsearch-leftside .amsearch-item-container.recent_searches {
    padding-top: 0;
    padding-bottom: 15px;
  }
  .amsearch-form-container.-opened .amsearch-wrapper-input {
    position: absolute;
    right: 0;
    max-width: 75vw;
    transition: all .3s ease-in-out;
  }
  .amsearch-products {
    display: flex;
    flex-direction: column;
  }
  .amsearch-products .amsearch-more-results {
    margin-top: auto;
    padding-top: 30px;
  }
  .amsearch-form-container.-small .amsearch-results {
    flex-direction: column;
    overflow-x: hidden;
  }
  .amsearch-form-container.-small .amsearch-products {
    order: 10;
  }
  .amsearch-form-container.-small .amsearch-products .amsearch-item {
    padding-right: 15px;
  }
  .amsearch-form-container.-small .amsearch-products .amasty-xsearch-block-header {
    padding: 15px;
  }
  .amsearch-form-container.-small.-result .amsearch-item-container .amasty-xsearch-block-header {
    padding: 0 15px 10px;
  }
  .amsearch-form-container.-small.-result .amsearch-item-container.popular_searches .amasty-xsearch-block-header {
    padding: 10px 15px;
  }
  .amsearch-form-container.-small .amsearch-products .amsearch-item.product-item .actions-secondary {
    display: flex;
    align-items: center;
  }
  .amsearch-form-container.-small .amsearch-products .amsearch-item.product-item .actions-secondary .wishlist.split.button {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  .amsearch-form-container.-small .amsearch-products .amsearch-item.product-item .actions-secondary .wishlist.split.button .action.toggle {
    display: flex;
    align-items: center;
  }
  .amsearch-form-container.-small .amsearch-products .amsearch-item.product-item .actions-secondary .wishlist.split.button .action.toggle:after {
    padding-top: 4px;
  }
  .amsearch-form-container.-large .amsearch-results .amsearch-more-results {
    padding-top: 0;
  }
  .amsearch-form-container.-large .amsearch-wrapper-content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  .amsearch-form-container.-large .amsearch-wrapper-content > .amsearch-item {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 15px;
    width: 100% / 3;
  }
  .ie11 .amsearch-form-container.-large .amsearch-wrapper-content > .amsearch-item {
    max-width: calc(100% / 3 - 25px);
  }
  .amsearch-form-container.-large .amsearch-wrapper-content > .amsearch-item:hover {
    box-shadow: none;
    z-index: 2;
  }
  .amsearch-form-container.-large .amsearch-wrapper-content .amsearch-autocomplete-information {
    flex-grow: 1;
    margin-top: 10px;
    padding-left: 0;
  }
  .amsearch-form-container.-large .amsearch-wrapper-content .product-item .product-image-container {
    margin: 0 auto;
  }
  .amsearch-form-container.-large .amsearch-wrapper-content .information-container {
    height: 100%;
  }
  .amsearch-form-container.-large .amsearch-wrapper-content .product-reviews-summary {
    margin-bottom: 7px;
  }
  .amsearch-form-container.-large .amsearch-wrapper-content .amsearch-autocomplete-image {
    align-items: center;
    width: auto;
  }
  .amsearch-form-container.-large .amsearch-wrapper-content .amsearch-wrapper-inner {
    flex-wrap: wrap;
    padding-left: 0;
  }
  .amsearch-form-container.-large .amsearch-wrapper-content .product-item-details {
    margin: 0;
    padding-right: 0;
    height: 100%;
  }
  .amsearch-form-container.-large .amsearch-wrapper-content .price-box {
    margin: 0 0 7px;
  }
  .amsearch-form-container.-large .amsearch-wrapper-content .price {
    font-size: 16px;
  }
  .amsearch-form-container.-large .amsearch-wrapper-content .product-item-actions {
    margin: 0;
  }
  .amsearch-form-container.-large .amsearch-wrapper-content .tocart {
    padding: 8px 9px;
    font-size: 12px;
  }
  .amsearch-form-container.-large .amsearch-wrapper-content .product-item-actions {
    display: flex;
    align-items: center;
    max-width: 100%;
  }
  .amsearch-form-container.-large .amsearch-wrapper-content .rating-summary {
    display: block;
  }
  .amsearch-form-container.-large .amsearch-wrapper-content .reviews-actions {
    display: block;
    margin-bottom: 1px;
  }
  .page-main .block.block-xsearch {
    float: none;
    padding-left: 0;
  }
  .footer .block.block-xsearch {
    padding-left: 0;
  }
  .footer .block.block-xsearch .block {
    float: none;
    clear: both;
  }
  .block-search .label.action {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
  .amsearch-emptysearch-cms {
    padding: 40px;
  }
  .amsearch-emptysearch-cms .amsearch-content > .amsearch-title {
    font-size: 54px;
  }
  .amsearch-emptysearch-cms .amsearch-text {
    font-size: 22px;
  }
}

@media screen and (min-width: 992px) {
  .amsearch-form-container.-opened .amsearch-wrapper-input,
  .search-autocomplete,
  .amsearch-results {
    max-width: 76vw;
  }
  .amsearch-wrapper-input.-left-position,
  .search-autocomplete.-left-position {
    right: inherit;
    left: 0;
  }
  .amsearch-wrapper-input.-right-position,
  .search-autocomplete.-right-position {
    left: inherit;
    right: 0;
  }
  .search-autocomplete.-top-position {
    bottom: calc(100% + 5px);
    top: inherit;
  }
  .search-autocomplete.-bottom-position {
    bottom: inherit;
    top: calc(100% + 5px);
  }
  .amsearch-emptysearch-cms {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 70px;
  }
  .amsearch-emptysearch-cms > .amsearch-item {
    max-width: 50%;
  }
  .amsearch-emptysearch-cms > .amsearch-content {
    padding-right: 10px;
  }
  .amsearch-emptysearch-cms .amsearch-content > .amsearch-title {
    margin: 0 0 25px;
    font-size: 72px;
  }
  .amsearch-emptysearch-cms .amsearch-text {
    font-size: 28px;
    line-height: 33px;
  }
  .amsearch-emptysearch-cms .amsearch-contacts-block {
    margin-top: 45px;
    font-size: 22px;
  }
  .amsearch-emptysearch-cms .amsearch-image-block {
    margin-top: 0;
  }
  .amsearch-emptysearch-cms .amsearch-contacts-block > .amsearch-title {
    margin-bottom: 10px;
  }
}

.am-porto-cmtb .page-header.type1 {
  z-index: 9999;
}

.am-porto-cmtb .amsearch-clone-position {
  padding: 2px 0;
  border: 15px solid transparent;
  border-top: none;
  border-radius: 0 0 20px 20px;
}

.am-porto-cmtb .amsearch-clone-position .amsearch-results {
  border-radius: 0 0 6px 6px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
}

.am-porto-cmtb .amsearch-autocomplete-information .product-reviews-summary .reviews-actions .action {
  margin: 0;
  padding: 0;
  border: 0;
}

.search-autocomplete .amsearch-slider-wrap:not(:first-child) {
  margin-top: 55px;
}

.search-autocomplete .amsearch-slider-block {
  margin-top: 15px;
}

.search-autocomplete .amsearch-slider-block .product-item-link {
  display: block;
  overflow: hidden;
  min-height: "";
  max-height: "";
  font-size: "";
  line-height: 1.4;
  word-wrap: break-word;
  word-break: break-word;
}

.ie11 .search-autocomplete .amsearch-slider-block .product-item-link {
  word-break: break-all;
}

.search-autocomplete .amsearch-slider-block.slick-slider {
  padding: 0;
}

.search-autocomplete .amsearch-slider-block .slick-slide {
  vertical-align: inherit;
}

.amsearch-additional-content {
  display: flex;
  margin: -15px 0;
  max-width: 100%;
}

.amsearch-additional-content .amsearch-leftside-sidebar,
.amsearch-additional-content .amsearch-content-block {
  padding: 15px 0;
}

.amsearch-additional-content .amsearch-leftside-sidebar {
  min-width: 30%;
}

.amsearch-additional-content .amsearch-content-block {
  box-sizing: border-box;
  max-width: 70%;
  border-left: 1px solid #e8e9ef;
}

.search-autocomplete .amsearch-slider-block .slick-dots {
  top: 100%;
  border: none;
  background: inherit;
}

.search-autocomplete .amsearch-slider-block .slick-dots > li[role='presentation'] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 2px;
  padding: 0;
  width: 12px;
  height: 12px;
  border: none;
  text-align: inherit;
  white-space: inherit;
}

.search-autocomplete .amsearch-slider-block .slick-dots > li[role='presentation']:hover, .search-autocomplete .amsearch-slider-block .slick-dots > li[role='presentation'].selected {
  background: inherit;
}

.search-autocomplete .amsearch-slider-block .slick-dots > li[role='presentation'].slick-active button {
  border-color: #000;
  background: #000;
}

.search-autocomplete .amsearch-slider-block .slick-dots > li[role='presentation'] button {
  padding: 3px;
  width: auto;
  height: auto;
  border: 1px solid #e8e9ef;
  border-radius: 100%;
  background: #fff;
}

.search-autocomplete .amsearch-slider-block .slick-dots > li[role='presentation'] button:before {
  content: none;
}

.search-autocomplete .amsearch-slider-block .slick-arrow {
  top: 70px;
  z-index: 2;
  width: 30px;
  height: 30px;
  border-radius: 0;
  background: #fff url("data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0uMTg5IDIuMDA5TDcuMTkgOS4wMS4yMzMgMTUuOTY4bDEuMTA4IDEuMTA5IDguMDQ0LTguMDQ0LTguMTEtOC4xMUwuMTg5IDIuMDA4eiIgZmlsbD0iIzU3NTg1NSIvPjwvc3ZnPg==") center no-repeat !important;
}

.search-autocomplete .amsearch-slider-block .amsearch-item.product-item {
  pointer-events: none;
}

.search-autocomplete .amsearch-slider-block .amsearch-item.product-item:hover, .search-autocomplete .amsearch-slider-block .amsearch-item.product-item.selected {
  background-color: transparent;
}

.search-autocomplete .amsearch-slider-block .amsearch-item.product-item .amsearch-autocomplete-image,
.search-autocomplete .amsearch-slider-block .amsearch-item.product-item .information-container {
  pointer-events: auto;
}

.search-autocomplete .amsearch-slider-block .amsearch-item.product-item .amsearch-autocomplete-image {
  width: 100%;
}

.search-autocomplete .amsearch-slider-block .amsearch-item.product-item .price-box {
  margin: 10px 0 5px;
}

.search-autocomplete .amsearch-slider-block .amsearch-item.product-item .actions-secondary {
  display: flex;
  align-items: center;
  padding: 5px;
}

.search-autocomplete .amsearch-slider-block .amsearch-item.product-item .wishlist.split.button .action.toggle {
  display: none;
}

.search-autocomplete .amsearch-slider-block .amsearch-item.product-item .actions-secondary > .action {
  flex-grow: inherit;
  margin-right: 5px;
  line-height: normal;
}

@media screen and (max-width: 991.98px) {
  .search-autocomplete .amsearch-slider-wrap:not(:first-child) {
    margin-top: 50px;
  }
  .amsearch-additional-content {
    flex-direction: column;
  }
  .amsearch-additional-content .amsearch-content-block {
    padding: 15px;
    max-width: 100%;
    border-top: 1px solid #e8e9ef;
    border-left: none;
  }
  .search-autocomplete .amsearch-slider-block .amsearch-item.product-item .amsearch-autocomplete-image {
    display: inline-block;
    margin-right: 10px;
    max-width: 110px;
  }
  .search-autocomplete .amsearch-slider-block .amsearch-item.product-item .information-container {
    display: inline-block;
    vertical-align: top;
  }
  .search-autocomplete .amsearch-slider-block .amsearch-item.product-item .amsearch-wrapper-inner {
    display: flex;
    align-items: center;
    margin: 10px 0 0;
  }
  .search-autocomplete .amsearch-slider-block .amsearch-item.product-item .price-box {
    margin: 0 20px 0 0;
  }
  .search-autocomplete .amsearch-slider-block .amsearch-item.product-item .product-item-actions {
    display: flex;
  }
}

@media screen and (max-width: 429.98px) {
  .search-autocomplete .amsearch-slider-block .amsearch-item.product-item {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .search-autocomplete .amsearch-slider-block .amsearch-item.product-item .amsearch-autocomplete-image,
  .search-autocomplete .amsearch-slider-block .amsearch-item.product-item .information-container {
    display: block;
    margin: 0 auto;
  }
  .search-autocomplete .amsearch-slider-block .amsearch-item.product-item .amsearch-wrapper-inner {
    flex-direction: column;
  }
  .search-autocomplete .amsearch-slider-block .amsearch-item.product-item .price-box {
    margin: 5px 0 10px;
  }
}

body {
  font-feature-settings: initial;
}

body .header.content {
  overflow: visible;
  justify-content: unset;
}

body .header.content .logo {
  width: 100%;
}

body .header.content .form.minisearch .control {
  width: unset;
  border-top: 0;
  margin: 0;
  padding: 0;
  clear: both;
  height: unset;
  transform: none;
  opacity: 1;
}

body .header.content .form.minisearch > .field {
  position: relative;
}

@media screen and (min-width: 992px) {
  body .header.content .form.minisearch > .field {
    position: unset;
    z-index: unset;
    margin-bottom: 0;
  }
}

body .header.content .form.minisearch > .field .label {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
  display: block;
  text-align: center;
  border: 2px solid #000;
  border-left: 0;
}

body .header.content .form.minisearch > .field .label:before {
  display: inline-block;
  line-height: 45px;
}

body .header.content .form.minisearch > .field .label.active:before {
  content: "";
}

body .header.content .form.minisearch > .field .amsearch-loupe,
body .header.content .form.minisearch > .field .amsearch-close {
  display: none !important;
}

body .header.content .form.minisearch > .field .amasty-xsearch-loader {
  right: 100px;
}

body .header.content .block-search {
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  z-index: 0;
}

@media screen and (max-width: 991.98px) {
  body .header.content .block-search {
    padding: 1rem;
    background-color: #fff;
    border-top: 1px solid #eee;
  }
}

@media screen and (min-width: 992px) {
  body .header.content .block-search {
    margin-right: unset;
    margin-left: unset;
    position: relative;
    z-index: 1;
  }
}

body .header.content .block-search .block-content {
  margin-bottom: 0;
}

body .header.content .amsearch-wrapper-input {
  position: relative;
  max-width: 100%;
  transition: none;
}

#search {
  border-radius: 0;
  padding: 4px 35px 0 26px;
  width: 100%;
  position: static;
  margin: 0;
  font-size: 16px;
  left: -300%;
  border: 2px solid #000;
  height: 46px;
  font-weight: bold;
  line-height: 46px;
}

#search:focus {
  border-color: #000;
}

@media screen and (min-width: 992px) {
  #search {
    font-size: 20px;
  }
}

#search::placeholder {
  color: #000;
  line-height: 45px;
}

#search_autocomplete {
  border: 0 !important;
  position: absolute;
}

#search_autocomplete .amsearch-item-container,
#search_autocomplete .amsearch-item-container .amsearch-highlight {
  font-size: 100%;
  letter-spacing: -0.02em;
}

#search_autocomplete .amsearch-products .information-container .amsearch-highlight {
  background-color: transparent !important;
  color: #b0aa00;
}

#search_autocomplete .amsearch-item-container .amsearch-highlight {
  color: #b0aa00;
}

#search_autocomplete .amsearch-item > .item-name {
  font-weight: bold;
  color: #000;
  text-decoration: none;
  font-size: 100%;
}

#search_autocomplete .amsearch-item > .item-name:hover {
  text-decoration: underline;
}

#search_autocomplete .price-box {
  margin-top: .5rem;
  margin-bottom: .5rem;
  width: 100%;
}

#search_autocomplete .price-box .price {
  font-size: 1.25rem;
}

#search_autocomplete .amasty-xsearch-block-header {
  font-family: "Univers Condensed Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.02em;
  font-size: 20px;
}

#search_autocomplete .amsearch-item-container.popular_searches .amasty-xsearch-block-header {
  padding: 0 0 10px;
}

#search_autocomplete .amsearch-item-container.recent_searches .amsearch-item:hover {
  border-color: #b0aa00;
}

#search_autocomplete .amsearch-item-container.recent_searches .amsearch-item > a {
  font-size: 100%;
}

#search_autocomplete .product.actions.product-item-actions {
  justify-content: center;
  width: 100%;
}

body .amsearch-results {
  padding-bottom: 15px;
}

@media screen and (min-width: 992px) {
  body .amsearch-results {
    padding-top: 0;
  }
}

/* top spacing breadcrumbs on mobile  */
@media screen and (max-width: 991.98px) {
  body .page-wrapper .breadcrumbs {
    margin-top: 5rem;
  }
}

@media screen and (max-width: 991.98px) {
  body.page-layout-2columns-left .page-wrapper .breadcrumbs {
    margin-top: 6rem;
  }
}

/* top spacing without breadcrumbs on mobile */
@media screen and (max-width: 991.98px) {
  body.cms-index-index .column.main,
  body.page-layout-1column .page-main {
    margin-top: 80px;
  }
}

body .amsearch-category-tab .amsearch-item {
  font-size: 1rem;
}

body #amsearch-result-tabs .title.active .switch {
  padding-bottom: 1rem;
}

body #amsearch-result-tabs .data.item.content {
  padding: 1.5rem;
}

@media screen and (max-width: 991.98px) {
  body #amsearch-result-tabs .title .switch {
    justify-content: flex-start;
  }
  body #amsearch-result-tabs .title .switch:after {
    position: absolute;
    top: 24px;
    right: 21px;
  }
}

body #amsearch-result-tabs .amsearch-related-terms > .amsearch-item {
  background-color: #000;
  color: #fff;
  font-size: 100%;
}

body #amsearch-result-tabs .amsearch-related-terms > .amsearch-item:hover {
  background-color: #97999C;
  color: #fff;
}

body #amsearch-result-tabs .amsearch-related-terms > .amsearch-title {
  font-family: "Univers Blackest", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-transform: uppercase;
}

body .product-item .action.tocompare {
  display: none;
}

body .amsearch-form-container .input-text:focus {
  box-shadow: 0 4px 5px 1px #a9a9a9;
}

body .amasty-xsearch-loader {
  background: url(data:image/gif;base64,R0lGODlhEAALAPQAAP///wAAANra2tDQ0Orq6gYGBgAAAC4uLoKCgmBgYLq6uiIiIkpKSoqKimRkZL6+viYmJgQEBE5OTubm5tjY2PT09Dg4ONzc3PLy8ra2tqCgoMrKyu7u7gAAAAAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCwAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQJCwAAACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQJCwAAACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQJCwAAACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAkLAAAALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkECQsAAAAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAkLAAAALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkECQsAAAAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA) no-repeat center;
  background-size: cover;
}

/* override word wrapping settings within the autocomplete dropdown */
body .amsearch-item .product-item-description,
body .amsearch-item .item-description {
  word-break: break-word;
}

/* override link colour within the search result autocomplete */
body.is-search-open .amsearch-more-results .amsearch-link {
  border-bottom: unset;
  color: inherit;
  text-decoration: inherit;
}

body.is-search-open .amsearch-more-results .amsearch-link:hover {
  border-bottom: unset;
  background-color: unset;
  color: inherit;
  text-decoration: inherit;
}

/* re-order the search results - products to show first on mobile */
body .amsearch-products {
  order: unset;
}

/* hide description for the category list returned */
body .amsearch-results .amsearch-item-container.category .item-description {
  display: none;
}

/* reduce height of autocomplete dropdown due to view all link being cutt off some browsers */
body .amsearch-form-container .search-autocomplete {
  max-height: 85vh;
}

/* hide the description - which shows embedded styles with style tag omitted LGH-533 */
body .amsearch-category-tab .amsearch-item .item-description {
  display: none;
}
